@font-face {
  font-family: Conthrax;
  src: url("./Site_Assets/Fonts/conthrax-sb.ttf");
}

@font-face {
  font-family: Pirulen;
  src: url("./Site_Assets/Fonts/pirulen-rg.otf");
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #6b6b6b; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255); 
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #965d1c;
}

body {
  margin: 0;
  font-family: 'Pirulen', 'Conthrax', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  background-color: #965d1c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
