.grid a {
	cursor: pointer;
}

.grid {
	padding: 2% 15%;
	grid-template-columns: auto auto auto auto;
	gap: 10% 5%;
	/* grid-template-rows: 200%; */
	display: grid;
	justify-content: space-evenly;
}

.grid-item {
	background-color: dodgerblue;
	color: white;
	/* padding: 10%; */
	max-width: 100%;
	height: auto;
}

.modal-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, .80);
	/* animation: .5s ease-out 0s 1 fadeInUp; */
	overflow: hidden;
}

/* Standard syntax */
/* @keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
} */

.grid-mobile-links {
	grid-template-columns: auto auto;
	justify-content: center;
	gap: 28% 9%;
	align-items: center;
	justify-items: center;
	padding: 0%;
	position: relative;

	/* top: -30%; wont work bc container is relative*/
	margin-top: -88%;
	padding-bottom: 32%;
}

.query-mobile-grid {
	display: none;
}

.fa-style-mobile {
	color: white;
	font-size: 18vw; 
}

.hide-mobile {
	visibility: hidden;
	opacity: 0;

	transition: .5s;
}

.sc-container .grid-item {
	filter: brightness(100%);
	transition: all 0.3s ease;
}

.sc-container:hover .grid-item {
	filter: brightness(30%);
}

.sc-container:hover .hide-mobile {
	visibility: visible;
	opacity: 1;
}

/* Standard syntax */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1370px) {
	.grid {
		grid-template-columns: auto auto auto;
		gap: 10% 8%;
	}
}
@media (max-width: 991px) {
	.grid {
		grid-template-columns: auto auto;
		gap: 5% 8%;
	}
}

@media (max-width: 768px) {
	.grid {
		grid-template-columns: auto;
		gap: 2% 0%;
		padding: 7% 15%;
	}
	.query-mobile-grid {
		display: grid;
		z-index: 999;
	}
	.sc-container {
		width: 100%;
	}
	.sc-container .grid-item {
		transition: .5s;
	}
	.sc-container:hover .grid-item {
		filter: brightness(40%) blur(1px);
	}
}
