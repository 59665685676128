.fa-style {
	font-size: 8vw; 
}

.fa-link-container {
	transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
	transition-timing-function: linear;
}

.fa-link-container:hover {
	color: white !important;
	
}

.grid-modal {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: center;
	gap: 28% 18%;
	align-items: center;
	justify-items: center;
	padding: 0%;

	position: absolute;
    top: 45%; /* Change these for media queries */
    left: 50%; /**/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.coverContainer {
	width: 33%;
	z-index: 999;
	position: absolute;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Hover effects */
.coverContainer:hover .main-image {
	filter: brightness(40%) blur(2px);
}

.coverContainer:hover .hide {
	visibility: visible;
	opacity: 1;
	/* display: block; */
}

.hide {
	visibility: hidden;
	opacity: 0;

	transition: .5s;
	/* display: none; */
}

.main-image {
	z-index: -1;
	max-width: 100%;
	position: relative; 
	left: 50%;
	transform: translateX(-50%);

	transition: 0.3s;
}

.hide > h1 {
	font-size: 2.5vw;
	top: 5%;
    left: 50%;
	padding: 0%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
	position: absolute;
	color: white;
	overflow: hidden;
    white-space: nowrap;
}

h2 {
	font-family: Conthrax;
	font-size: 1.5vw;
	margin-top: 8%;
    left: 70%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
	position: relative;
	color: white;
}

@media (max-width: 1370px) {
	.fa-style {
		font-size: 12vw;
	}
	
	.coverContainer {
		width: 50%;
	}

	.hide > h1 {
		font-size: 4vw;
	}

	h2 {
		font-size: 2.25vw;
	}
}

@media (max-width: 991px) {
	.fa-style {
		font-size: 16vw;
	}
	
	.coverContainer {
		width: 65%;
	}

	.hide > h1 {
		font-size: 5vw;
	}

	h2 {
		font-size: 3vw;
	}
}

@media (max-width: 768px) {
	
}