/*
.home-container {

}
*/

/* Resizing window will resize class from center */
.center-pivot {
    width: 90%;
    height: fit-content;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  /* Centers Dancing Bungaboys */
  .dancing-bungaboys {
    display: flex; /* Use flexbox to align the GIFs next to each other */
    flex-wrap: wrap;
    justify-content: center; /* Center the flex container horizontally */
    align-items: center; /* Center the flex container vertically */
    height: 62vh; /* Set the height to 100vh to take up the full viewport height */
  }
  
  .dancing-bungaboys img {
    margin-right: 0px; /* Add spacing between the GIFs */
    max-height: 100%; /* Set the maximum height for the GIFs */
  }
  
  .center-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 59vh;
  }

  .center-image-container img {
    width: 16%;
  }
  /* NavButton grid */
  .flex-grid {
      display: flex;
    justify-content: space-between;
    padding: 8% 7% 1%; 
    /* padding: calc(50px + 1.5625vh) calc(8px + 1.5625vw); */
  }
  
  /* @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  } */

  /*------------------MEDIA ADJUSTMENTS-------------*/


@media (max-width: 1024px) {
    .center-image-container img {
      width: 20%;
    }
  }

@media (max-width: 768px) {

    .center-pivot {
        width: 100%;
        position: relative;
        left: auto;
        top: auto;
        transform: none;
    }

    .flex-grid {
        display: block;
        justify-content: center;
        padding: unset;
    }

    .container {
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 75%;
        height: auto;
    }

    .content {
        position: relative;
        height: unset;
    }

    .center-image-container {
        height: 50vh;
    }
    
    .center-image-container img {
      width: 26%;
    }

    /* Landscape */
    @media screen and (orientation: landscape) {
      .center-image-container img {
        width: 16%;
      }
    }

    /* Portrait */
    @media screen and (orientation: portrait) {
      .center-image-container img {
        width: 30%;
      }
    }
}


@media (max-width: 425px) {
    .center-image-container img {
      width: 48%;
    }
}

